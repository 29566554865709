
@import (reference) "../website.less";
@import (reference) "../Website/Base.less";

body.path-- {
    #hero-container {
        position: relative;
        color: @coal;

        @media (max-width: @breakpoint-lg) {
            min-height: 780px;
        }

        #home-hero-images-mobile {
            display: none;

            @media (max-width: @breakpoint-lg) {
                display: block;
            }

            .images-container {
                @width: 33%;
                @height: @width * 0.6666666;
                width: 100%;
                overflow: hidden;

                img {
                    float: left;
                    width: @width;
                    height: @height;
                }
            }

        }

        .hero-content {
            .content-text {
                padding-top: 2rem;

                @media (min-width:@breakpoint-lg) {
                    padding-top: 4.7rem;

                }

                @media (min-width:@breakpoint-lg) {}

                h1 {
                    letter-spacing: -0.01em;
                    font-weight: 300;
                    font-weight: bold;
                    margin-bottom: 2rem;
                }
                h2, .h2withh1style{
                    letter-spacing: -0.01em;
                    font-weight: 300;
                    font-weight: bold;
                    margin-bottom: 2rem;
                }

                p {
                    @media (min-width: @breakpoint-lg) {
                        max-width: 16em;
                    }

                    font-weight: 300;
                    margin-bottom: 1.5rem;

                }

                .hero-text-desktop,
                .hero-text-mobile {
                    display: none;
                }

                .hero-text-desktop {
                    @media (min-width: @breakpoint-lg) {
                        display: block;
                    }
                }

                .hero-text-mobile {
                    @media (max-width: @breakpoint-lg) {
                        display: block;
                    }
                }


                .btn {
                    margin-top: 1.5rem;
                    font-size: 1.3rem;

                    @media (max-width:@breakpoint-lg) {
                        margin-top: 0;
                    }
                }

                @media (max-width:@breakpoint-lg) {
                    margin-top: 0;
                    margin-bottom: 0rem;
                }
            }

            #home-hero-images {
                @media (max-width:@breakpoint-lg) {}
            }
        }
    }

    .page-content {
        
        #the-easiest {
            #review-widget {
                height:100%;
                justify-content: center;
                align-items: center;
            }
        }

        #the-simplest,
        #the-easiest,
        #the-fastest {
            padding-top: 4rem;
            padding-bottom: 4rem;

            @media (max-width: @breakpoint-lg) {
                padding-top: 2rem;
                padding-bottom: 2rem;
            }

            .container {
                @media (max-width: @breakpoint-md) {
                    min-height: 420px;
                }
                .content-text {
                    h1 {
                        .font-size(3.3rem);
                        margin-bottom: 0.4em;
                    }

                    h2,
                    .h2withh1style {
                        .font-size(3.3rem);
                        margin-bottom: 0.4em;
                    }

                    p {
                        .font-size(1.1rem);
                        margin-bottom: 1.5em;
                        letter-spacing: normal;
                        max-width: 480px;
                    }

                    .btn {
                        font-size: 1.25rem;
                    }
                }
            }
        }
    }

    #the-fastest {
        .placeholder-image-container {
            img {
                width: 100%;
                height: auto;

                @media (min-width: @breakpoint-md) {
                    width: 100%;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            @media (min-width: @breakpoint-md) {
                margin: -2rem 0 -5rem 0;
            }
        }
    }

    #the-simplest {

        .check-list {
            list-style: none;
            padding: 0;

            li {
                position: relative;
                font-size: 2.2em;
                .font-acumin;
                font-weight: bold;
                margin-bottom: 0.2em;
                padding-left: 1.75em;

                @media (max-width:@breakpoint-lg) {
                    font-size: 1.5em;
                }

                &:before {
                    content: " ";
                    background-image: url('https://cdn2.hubspot.net/hubfs/2929749/site/static/images/check-badge.svg');
                    background-size: 100%;
                    background-repeat: no-repeat;
                    @size: 1.2em;
                    width: @size;
                    height: @size;

                    @media (max-width:@breakpoint-lg) {
                        @size: 1em;
                        width: @size;
                        height: @size;
                    }

                    position: absolute;
                    top: 0.25em;
                    left: 0;
                }
            }
        }
    }
}